import i18n from '@/locale';

const timeIntervalType = {
	'stack': {
		'7': i18n.t('member.previous7Days'),
		'15': i18n.t('member.previous15Days'),
		'30': i18n.t('member.previous30Days'),
		'90': i18n.t('member.previous90Days'),
		'360': i18n.t('member.previous360Days'),
		'99999': i18n.t('member.overAll'),
	},
	'sequence': {
		'000': i18n.t('member.000'),
		'007': i18n.t('member.007'),
		'030': i18n.t('member.030'),
		'120': i18n.t('member.120'),
		'360': i18n.t('member.360'),
		'last': i18n.t('member.last'),
	},
	'afwd': {
		'cvt_gm': i18n.t('charts.gameConver'),
		'0~1': i18n.t('member.a001'),
		'2~7': i18n.t('member.a002'),
		'8~29': i18n.t('member.a008'),
	},
	// 'afwd2': {
	// 	'1': i18n.t('member.a001'),
	// 	'3': i18n.t('member.a003'),
	// 	'7': i18n.t('member.a007'),
	// 	'15': i18n.t('member.a015'),
	// 	'30': i18n.t('member.a030'),
	// },
	'bkwd': {
		'0~1': i18n.t('member.b001'),
		'2~7': i18n.t('member.b002'),
		'8~29': i18n.t('member.b008'),
		'30~89': i18n.t('member.b030'),
		'90~359': i18n.t('member.b090'),
		'360++': i18n.t('member.b360'),
	},
	'top': {
		'0': '0',
		'0.4': '0.4',
		'0.8': '0.8',
		'0.9': '0.9',
		'0.95': '0.95',
		'0.99': '0.99',
	}
};
export default timeIntervalType;
