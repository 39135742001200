import _ from 'lodash';
import moment from 'moment';
import repla, { replaObjInArray, replaObj } from '@/util/dataFilter';
import fetch from '@/util/fetch';
import i18n from '@/locale';
import deviceType from '@/assets/constant/deviceType';
import statusCode from '@/assets/constant/statusCode';
import timeIntervalType from '@/assets/constant/timeIntervalType';
import transType from '@/assets/constant/transType';
import { pRank, rdPercent, roundDecimal } from '@/util/format';

export async function getSingleMember(reqData) {
	const result = await fetch({
		url: '/cars/ocms/customer/single/member',
		method: 'post',
		data: {
			reqData,
		},
	});
	if (result.status === statusCode.STATUS_OK) {
		const tmp = result.result.res;
		replaObjInArray(tmp.ctx_agg_trans);
		replaObjInArray(tmp.ctx_popular_times);
		replaObjInArray(tmp.ctx_rpt_recap.gm_sub);
		replaObjInArray(tmp.ctx_rpt_recap.termly_all);
		replaObj(tmp.ctx_rpt_recap.basic_all);
		for (const i of tmp.ctx_agg_trans) {
			i.dim_txn_type = transType[i.dim_txn_type];
		}
		tmp.ctx_rpt_recap.basic_all.dpst_time_max = (tmp.ctx_rpt_recap.basic_all.dpst_time_max) ? moment.utc(tmp.ctx_rpt_recap.basic_all.dpst_time_max.value).format('YYYY-MM-DD') : 'N/A';
		tmp.ctx_rpt_recap.basic_all.gm_time_max = (tmp.ctx_rpt_recap.basic_all.gm_time_max) ? moment.utc(tmp.ctx_rpt_recap.basic_all.gm_time_max.value).format('YYYY-MM-DD') : 'N/A';
		tmp.ctx_rpt_recap.basic_all.reg_time_min = (tmp.ctx_rpt_recap.basic_all.reg_time_min) ? moment.utc(tmp.ctx_rpt_recap.basic_all.reg_time_min.value).format('YYYY-MM-DD') : 'N/A';
		for (const i of tmp.ctx_rpt_recap.gm_sub) {
			i.apv_bet = parseFloat(i.apv_bet);
			i.dim_device_id = deviceType[i.dim_device_id];
			i.house_net_win = parseFloat(i.house_net_win);
			i.game_name = i.dim_brand_id +'-'+ i.game_theme_name;
		}
		for (const i of tmp.ctx_rpt_recap.termly_all) {
			i.avg_bet_per_day = parseFloat(i.avg_bet_per_day);
			i.avg_dpst_amt_per_day = parseFloat(i.avg_dpst_amt_per_day);
			i.dim_prev_days = timeIntervalType.stack[i.dim_prev_days.toString()];
		}
	}
	return result;
}

export async function getSingleSite(reqData) {
	const result = await fetch({
		url: '/cars/ocms/customer/single/site',
		method: 'post',
		data: {
			reqData,
		},
	});
	if (result.status === statusCode.STATUS_OK) {
		const tmp = result.result.res;
		tmp.ctx_cvt_org_early = [];
		tmp.ctx_cvt_all_early = [];
		tmp.ctx_act_org = [];
		tmp.ctx_act_sum = [];
		tmp.ctx_act_all = [];
		tmp.ctx_cvt_org = [];
		tmp.ctx_cvt_all = [];
		tmp.ctx_kpi_org = [];
		tmp.ctx_kpi_sum = [];
		tmp.ctx_kpi_all = [];
		// const day = moment(reqData.endDate).diff(reqData.startDate, 'days') + 1;
		for (const i of tmp.ctx_cvt_early) {
			if(i.dim_ptt) {
				i.dim_ptt = moment.utc(i.dim_ptt.value).format('YYYY-MM-DD HH:mm:ss');
				tmp.ctx_cvt_org_early.push(i);
			} else {
				i.dim_ptt = null;
				tmp.ctx_cvt_all_early.push(i);
			}
		}
		for (const i of tmp.ctx_cvt) { //ctx_site_cvt
			if(i.dim_ptt) {
				i.dim_ptt = moment.utc(i.dim_ptt.value).format('YYYY-MM-DD HH:mm:ss');
				tmp.ctx_cvt_org.push(i);
			} else {
				i.dim_ptt = null;
				tmp.ctx_cvt_all.push(i);
			}
		}
		for (const i of tmp.ctx_gaming) {
			i.dim_device_id = _.toInteger(i.dim_device_id) ?  i18n.t('common.mobile') : i18n.t('common.pc');
			i.game_brand_theme = i.dim_brand_code + '-' + i.game_theme_name;
		}
		for (const i of tmp.ctx_kpi_stk) {
			if(!i.dim_reg_age) {
				tmp.ctx_kpi_all.push(i);
			}
			if(i.dim_reg_age !== null) {
				if(i.dim_ptt) {
					i.dim_ptt = moment.utc(i.dim_ptt.value).format('YYYY-MM-DD');
					tmp.ctx_kpi_org.push(i);
				} else {
					tmp.ctx_kpi_sum.push(i);
				}
			}
		}
		for (const i of tmp.ctx_recap_early) {
			i.avg_apv_bet = parseFloat(i.avg_apv_bet);
			i.avg_bonus_amt = parseFloat(i.avg_bonus_amt);
			i.avg_dpst_amt = parseFloat(i.avg_dpst_amt);
			i.avg_house_net_win = parseFloat(i.avg_house_net_win);
			i.avg_in_promo_bet = parseFloat(i.avg_in_promo_bet);
			i.avg_no_promo_bet = parseFloat(i.avg_no_promo_bet);
			i.avg_profit_amt = parseFloat(i.avg_profit_amt);
			i.avg_revenue_amt = parseFloat(i.avg_revenue_amt);
			i.avg_wdrl_amt = parseFloat(i.avg_wdrl_amt);
			i.house_edge = parseFloat(i.house_edge);
			i.ttl_in_promo_bet = parseFloat(i.ttl_in_promo_bet);
			i.ttl_no_promo_bet = parseFloat(i.ttl_no_promo_bet);
			i.pupd_apv_bet = parseFloat(i.pupd_apv_bet);
			i.pupd_dpst_amt = parseFloat(i.pupd_dpst_amt);
			i.ttl_apv_bet = parseFloat(i.ttl_apv_bet);
			i.ttl_bonus_amt = parseFloat(i.ttl_bonus_amt);
			i.ttl_dpst_amt = parseFloat(i.ttl_dpst_amt);
			i.ttl_house_net_win = parseFloat(i.ttl_house_net_win);
			i.ttl_profit_amt = parseFloat(i.ttl_profit_amt);
			i.ttl_revenue_amt = parseFloat(i.ttl_revenue_amt);
			i.ttl_wdrl_amt = parseFloat(i.ttl_wdrl_amt);
		}
		for (const i of tmp.ctx_recap) {
			i.avg_apv_bet = parseFloat(i.avg_apv_bet);
			i.avg_bonus_amt = parseFloat(i.avg_bonus_amt);
			i.avg_dpst_amt = parseFloat(i.avg_dpst_amt);
			i.avg_house_net_win = parseFloat(i.avg_house_net_win);
			i.avg_in_promo_bet = parseFloat(i.avg_in_promo_bet);
			i.avg_no_promo_bet = parseFloat(i.avg_no_promo_bet);
			i.avg_profit_amt = parseFloat(i.avg_profit_amt);
			i.avg_revenue_amt = parseFloat(i.avg_revenue_amt);
			i.avg_wdrl_amt = parseFloat(i.avg_wdrl_amt);
			i.house_edge = parseFloat(i.house_edge);
			i.ttl_in_promo_bet = parseFloat(i.ttl_in_promo_bet);
			i.ttl_no_promo_bet = parseFloat(i.ttl_no_promo_bet);
			i.pupd_apv_bet = parseFloat(i.pupd_apv_bet);
			i.pupd_dpst_amt = parseFloat(i.pupd_dpst_amt);
			i.ttl_apv_bet = parseFloat(i.ttl_apv_bet);
			i.ttl_bonus_amt = parseFloat(i.ttl_bonus_amt);
			i.ttl_dpst_amt = parseFloat(i.ttl_dpst_amt);
			i.ttl_house_net_win = parseFloat(i.ttl_house_net_win);
			i.ttl_profit_amt = parseFloat(i.ttl_profit_amt);
			i.ttl_revenue_amt = parseFloat(i.ttl_revenue_amt);
			i.ttl_wdrl_amt = parseFloat(i.ttl_wdrl_amt);
		}
		for (const i of tmp.ctx_termly) {
			i.apv_bet = parseFloat(i.apv_bet);
			i.bonus_amt = parseFloat(i.bonus_amt);
			i.dim_ptt = moment.utc(i.dim_ptt.value).format('YYYY-MM-DD HH:mm:ss');
			i.dpst_amt = parseFloat(i.dpst_amt);
			i.house_net_win = parseFloat(i.house_net_win);
			i.in_promo_bet = parseFloat(i.in_promo_bet);
			i.revenue_amt = parseFloat(i.revenue_amt);
			i.wdrl_amt = parseFloat(i.wdrl_amt);
		}
		for (const i of tmp.ctx_kpi_ret[0].ctx_act_agg) {
			if(i.dim_ptt) {
				i.dim_ptt = (i.dim_ptt) ? moment.utc(i.dim_ptt.value).format('YYYY-MM-DD') : null;
				i.tag_reg_term = _.toString(i.tag_reg_term);
				tmp.ctx_act_org.push(i);
			} else {
				if(!_.isNull(i.tag_reg_term)) {
					i.tag_reg_term = _.toString(i.tag_reg_term);
					tmp.ctx_act_sum.push(i);
				} else {
					tmp.ctx_act_all.push(i);
				}
			}
		}
		for(const i of tmp.ctx_kpi_ret[0].ctx_act_each) {
			i.dim_ptt = (i.dim_ptt) ? moment.utc(i.dim_ptt.value).format('YYYY-MM-DD') : null;
			i.retention = !_.isNull(i.retention)? i.retention: 0;
			i.ttl_gm_users_esti = !_.isNull(i.ttl_gm_users_esti)? parseFloat(i.ttl_gm_users_esti): 0;
			i.ttl_gm_users_real = !_.isNull(i.ttl_gm_users_real)? parseFloat(i.ttl_gm_users_real): 0;
		}
		for (const i of tmp.ctx_trans) {
			i.dim_txn_type = transType[i.dim_txn_type];
		}
	}
	return result;
}

export async function getMemberLookup(reqData) {
	const result = await fetch({
		url: '/cars/ocms/customer/single/lookup',
		method: 'post',
		data: {
			reqData,
		},
	});
	if (result.status === statusCode.STATUS_OK) {
		const tmp = result.result.res;
		if (tmp.length > 0) {
			// 陣列內的物件
			replaObjInArray(tmp);
			for (const i of tmp) {
				i.ttl_dpst_attn_time_max = i.ttl_dpst_attn_time_max ? moment.utc(i.ttl_dpst_attn_time_max.value).format('YYYY-MM-DD'): 'N/A';
				i.ttl_gm_attn_time_max = i.ttl_gm_attn_time_max ? moment.utc(i.ttl_gm_attn_time_max.value).format('YYYY-MM-DD'): 'N/A';
				i.ttl_login_attn_time_max = i.ttl_login_attn_time_max ? moment.utc(i.ttl_login_attn_time_max.value).format('YYYY-MM-DD'): 'N/A';
				i.ttl_reg_attn_time_min = i.ttl_reg_attn_time_min ? moment.utc(i.ttl_reg_attn_time_min.value).format('YYYY-MM-DD'): 'N/A';
				i.ttl_wdrl_attn_time_max = i.ttl_wdrl_attn_time_max ? moment.utc(i.ttl_wdrl_attn_time_max.value).format('YYYY-MM-DD'): 'N/A';

				i.avg_dpst_stickiness = i.avg_dpst_stickiness? rdPercent(i.avg_dpst_stickiness): 0;
				i.avg_gm_stickiness = i.avg_gm_stickiness? rdPercent(i.avg_gm_stickiness): 0;

				i.avg_dpst_count = i.avg_dpst_count? roundDecimal(i.ttl_gm_apv_bet_amt): 0;
				i.avg_gm_count = i.avg_gm_count? roundDecimal(i.avg_gm_count): 0;
				i.ttl_dpst_count = i.ttl_dpst_count? roundDecimal(i.ttl_dpst_count): 0;
				i.ttl_gm_count = i.ttl_gm_count? roundDecimal(i.ttl_gm_count): 0;
				i.ttl_login_count = i.ttl_login_count? roundDecimal(i.ttl_login_count): 0;
				i.ttl_promo_done_count = i.ttl_promo_done_count? roundDecimal(i.ttl_promo_done_count): 0;
				i.ttl_promo_dpst_count = i.ttl_promo_dpst_count? roundDecimal(i.ttl_promo_dpst_count): 0;
				i.ttl_promo_join_count = i.ttl_promo_join_count? roundDecimal(i.ttl_promo_join_count): 0;
				i.ttl_wdrl_count = i.ttl_wdrl_count? roundDecimal(i.ttl_wdrl_count): 0;

				i.avg_dpst_amt_pr = i.avg_dpst_amt_pr? pRank(parseFloat(i.avg_dpst_amt_pr)): 0;
				i.avg_dpst_count_pr = i.avg_dpst_count_pr? pRank(parseFloat(i.avg_dpst_count_pr)): 0;
				i.avg_gm_apv_bet_amt_pr = i.avg_gm_apv_bet_amt_pr? pRank(parseFloat(i.avg_gm_apv_bet_amt_pr)): 0;
				i.avg_gm_auto_bet_amt_pr = i.avg_gm_auto_bet_amt_pr? pRank(parseFloat(i.avg_gm_auto_bet_amt_pr)): 0;
				i.avg_gm_count_pr = i.avg_gm_count_pr? pRank(parseFloat(i.avg_gm_count_pr)): 0;

				i.avg_dpst_amt = i.avg_dpst_amt? roundDecimal(parseFloat(i.avg_dpst_amt)): 0;
				i.avg_gm_apv_bet_amt = i.avg_gm_apv_bet_amt? roundDecimal(parseFloat(i.avg_gm_apv_bet_amt)): 0;
				i.avg_gm_auto_bet_amt = i.avg_gm_auto_bet_amt? roundDecimal(parseFloat(i.avg_gm_auto_bet_amt)): 0;
				i.ttl_dpst_amt = i.ttl_dpst_amt? roundDecimal(parseFloat(i.ttl_dpst_amt)): 0;
				i.ttl_gm_apv_bet_amt = i.ttl_gm_apv_bet_amt? roundDecimal(parseFloat(i.ttl_gm_apv_bet_amt)): 0;
				i.ttl_gm_auto_bet_amt = i.ttl_gm_auto_bet_amt? roundDecimal(parseFloat(i.ttl_gm_auto_bet_amt)): 0;
				i.ttl_gm_net_win_amt = i.ttl_gm_net_win_amt? roundDecimal(parseFloat(i.ttl_gm_net_win_amt)): 0;
				i.ttl_promo_apv_bet_amt = i.ttl_promo_apv_bet_amt? roundDecimal(parseFloat(i.ttl_promo_apv_bet_amt)): 0;
				i.ttl_promo_bonus_in_amt = i.ttl_promo_bonus_in_amt? roundDecimal(parseFloat(i.ttl_promo_bonus_in_amt)): 0;
				i.ttl_promo_bonus_out_amt = i.ttl_promo_bonus_out_amt? roundDecimal(parseFloat(i.ttl_promo_bonus_out_amt)): 0;
				i.ttl_promo_bonus_amt = roundDecimal(i.ttl_promo_bonus_out_amt - i.ttl_promo_bonus_in_amt); //not in api
				i.ttl_promo_dpst_amt = i.ttl_promo_dpst_amt? roundDecimal(parseFloat(i.ttl_promo_dpst_amt)): 0;
				i.ttl_promo_net_win_amt = i.ttl_promo_net_win_amt? roundDecimal(parseFloat(i.ttl_promo_net_win_amt)): 0;
				i.ttl_revenue_amt = i.ttl_revenue_amt? roundDecimal(parseFloat(i.ttl_revenue_amt)): 0;
				i.ttl_wdrl_amt = i.ttl_wdrl_amt? roundDecimal(parseFloat(i.ttl_wdrl_amt)): 0;
				// 最外層物件的key
				// for (let j of key) {
				// 	// console.log('j', j);
				// 	if (_.isObject(i[j])) {
				// 		let secKeys = Object.keys(i[j]);
				// 		// 第二層物件的key
				// 		for (let k of secKeys) {
				// 			// 判斷是否null
				// 			obj[j + '.' + k] = _.isNull(i[j][k]) ? 0 : i[j][k];
				// 		}
				// 	} else {
				// 		obj[j] = i[j];
				// 	}
				// }
				// array.push(obj);
			}
		}
		// avg_dpst_amt
		// avg_dpst_amt_pr
		// avg_dpst_count
		// avg_dpst_count_pr
		// avg_dpst_stickiness
		// avg_gm_apv_bet_amt
		// avg_gm_apv_bet_amt_pr
		// avg_gm_auto_bet_amt
		// avg_gm_auto_bet_amt_pr
		// avg_gm_count
		// avg_gm_count_pr
		// avg_gm_stickiness
		// dim_member_user_id
		// this_attn_term
		// ttl_dpst_amt
		// ttl_dpst_attn_term
		// ttl_dpst_attn_time_max
		// ttl_dpst_count
		// ttl_gm_apv_bet_amt
		// ttl_gm_attn_term
		// ttl_gm_attn_time_max
		// ttl_gm_auto_bet_amt
		// ttl_gm_count
		// ttl_gm_net_win_amt
		// ttl_login_attn_term
		// ttl_login_attn_time_max
		// ttl_login_count
		// ttl_promo_apv_bet_amt
		// ttl_promo_bonus_in_amt
		// ttl_promo_bonus_out_amt
		// ttl_promo_done_count
		// ttl_promo_dpst_amt
		// ttl_promo_dpst_count
		// ttl_promo_join_count
		// ttl_promo_net_win_amt
		// ttl_reg_attn_term
		// ttl_reg_attn_time_min
		// ttl_revenue_amt
		// ttl_wdrl_amt
		// ttl_wdrl_attn_term
		// ttl_wdrl_attn_time_max
		// ttl_wdrl_count
	}
	return result;
}