<template lang='pug'>
ValidationObserver(v-slot='{ invalid, validated, passes, validate }')
	v-overlay(:value='show', opacity='0.8', z-index='6')
		v-progress-circular(indeterminate, color='primary')
	v-card.filter-wrap
		v-tabs.filter-tab(v-model='tab', color='#fff', hide-slider)
			v-tab {{ $t("charts.everyDay") }}
		v-tabs-items.mt-1.mb-5.filter-wrap(v-model='tab')
			v-tab-item
				v-card.pa-4.py-0.filter-wrap(height='60px')
					v-row
						v-col.px-3(cols='12', sm='8', md='6', lg='3')
							DaliyPicker(:tab='tab', @emitupdateDate='updateDate')
		v-card.pt-0.pa-4.pb-4.filter-wrap(v-resize='onResize')
			v-row.justify-end.exportBtnUpper
				v-col.pa-4(v-if='windowSize.x <= 599')
				v-col.text-right.pa-0(v-if='windowSize.x > 599')
					div(@click='showhelperText')
						ExportBtn(:exportResult='getExportResult', @emitexportData='exportXlsxFile')
						span.ml-auto.mr-7.helperText(:class='{ show: helperShow }') {{ $t("common.helpText") }}
			//- v-row.rowUpper
			//- 	v-col.py-0(v-if='areaSwitch', cols='12', md='6', lg='6')
			//- 		v-row.input-wrap.mx-0
			//- 			v-col.pa-0.labal-bgc.text-center(cols='3')
			//- 				span.caption {{ $t("common.cmsList") }}
			//- 			v-col.pa-0(cols='9')
			//- 				ValidationProvider(rules='required', v-slot='{ errors }')
			//- 					v-select.memberSelect.cars-select(
			//- 						v-model='area',
			//- 						:items='areaList',
			//- 						required,
			//- 						:error-messages='errors',
			//- 						single-line,
			//- 						background-color='rgba(255, 255, 255, 0.1)',
			//- 						dense,
			//- 						solo,
			//- 						item-color='#fff',
			//- 						:menu-props='menuProps',
			//- 						return-object
			//- 					)
			//- 						template(v-slot:selection='{ item, index }')
			//- 							span(v-if='selectItemName(area, areaList, index)')
			//- 								span {{ item }}
			//- 	v-col.py-0(v-if='domainSwitch', cols='12', md='6', lg='6')
			//- 		v-row.input-wrap.mx-0
			//- 			v-col.pa-0.labal-bgc.text-center(cols='3')
			//- 				span.caption {{ $t("charts.domain") }}
			//- 			v-col.pa-0(cols='9')
			//- 				ValidationProvider(rules='required', v-slot='{ errors }')
			//- 					v-select.memberSelect.cars-select(
			//- 						v-model='value',
			//- 						:items='domain',
			//- 						required,
			//- 						:error-messages='errors',
			//- 						single-line,
			//- 						background-color='rgba(255, 255, 255, 0.1)',
			//- 						dense,
			//- 						solo,
			//- 						item-color='#fff',
			//- 						:menu-props='menuProps'
			//- 					)
			//- 						template(v-slot:selection='{ item, index }')
			//- 							span.text-center(v-if='selectItemName(value, domain, index)')
			//- 								span {{ item }}
			SingleSelectCurrency(:currencyStatus='currencyStatus', @emitDomainValue='updateDomainValue')
			v-card.filter-wrap.div-w.rowUpperWithoutRow(outlined, :loading='chipCardLoading')
				v-card-actions
					v-card-subtitle.subtitle {{ $t("member.selectTag") }}
					v-spacer
					v-btn.mr-5(icon, @click='drop = !drop')
						v-icon(large) {{ drop ? "expand_less" : "expand_more" }}
				v-expand-transition
					div(v-show='drop')
						v-divider
						v-chip-group(v-model='chipData', column, multiple)
							template(v-for='(item, index) in tagList')
								v-chip.ml-2(filter, outlined, dark, :value='item.name') {{ item.name }}
						v-spacer
							v-card-actions
								v-spacer
								v-btn.md-2(color='blue', @click='initChip', outlined) {{ $t("common.default") }}
								v-btn.md-2(color='orange', @click='allSelect', outlined) {{ $t("common.selectAll") }}
								v-btn.md-2(color='grey', @click='cancelSelect', outlined) {{ $t("common.clearAll") }}
			v-row.justify-center
				v-col(cols='12', sm='8', md='6', lg='3')
					v-btn.searchBtn.defult-btn-color(@click='passes(send)', dark, rounded, style='width: 100%; height: 55px') {{ $t("common.search") }}
			v-card.pa-4.filter-wrap.div-w
				v-col.py-0.fixBar.searchDate.justify-center(cols='12', md='6', lg='5', @click='goSearch')
					v-row.py-1
						v-col.ml-5.mr-auto.py-1.px-3(cols='auto')
							span.sub-title {{ searchDates[0] }} ～ {{ searchDates[1] }}
						v-col.mr-2.py-1.px-3(cols='auto')
							v-img.researchImg(max-width='25px', :src='imgSrc')
			v-row.justify-center(v-if='basic.items.length !== 0')
				v-col.pa-0(cols='12')
					v-data-table.elevation-1(
						:headers='basic.headers',
						:items='basic.items',
						:items-per-page='100',
						:footer-props='{ "items-per-page-options": rowsPerPageItems }',
						:height='800',
						dense
					)
						template(v-slot:body='{ items }')
							tbody
								tr(v-for='(item, index) in items', :key='index')
									template
										th(ref='dim_member_user_id') {{ item["dim_member_user_id"] }}
											v-btn.open-new-tab(icon, width='32px', height='32px', @click='searchMember(item)')
												v-icon(dense, color='orange') open_in_new
									template(v-for='(tag, index) in oldTagList')
										td.text-right(v-if='tag.value') {{ item[chipMap[tag.name]] | commaFormatter }}
			v-dialog(v-model='dialog', max-width='500px', overlay-opacity='0.9', :retain-focus='false')
				v-card
					v-card-title
						span.text-center.headline {{ $t("customer.areYouSureYouWantToSearchMember") }}
						span.text-center {{ selectedMember }} {{ $t("customer.areYouSureYouWantToSearchMember2") }}
					v-col.d-flex.justify-space-between
						v-btn.mr-3(color='grey darken-2', dark, rounded, @click='closeDialog') {{ $t("common.cancel") }}
						router-link(target="_blank" to='/house/single', style='text-decoration: none; color: white')
							v-btn.defult-btn-color(dark, rounded, @click='jumpPage(selectedMember)') {{ $t("common.confirm") }}
</template>
<style>
.subtitle {
	color: white !important;
}
.open-new-tab {
	top: 0px;
	right: 0px;
	position: absolute;
}
thead th {
	position: sticky;
	top: 0;
	background: #33373f;
}
tbody th {
	position: sticky;
	left: 0;
	border-right: 1px solid #ccc;
	background: #33373f;
}
table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
	position: sticky !important;
	position: -webkit-sticky !important;
	top: 0;
	left: 0;
	z-index: 9; /*全屏遮罩放在10*/
	background: #33373f;
}
table > thead > tr > th:nth-child(1) {
	z-index: 8;
	background: #33373f;
}
</style>
<script>
import ECharts from 'vue-echarts';
import { errorLogger } from '@/util/logger';
import { exportXlsx } from '@/util/xlsx';
import { getMemberLookup } from '@/api/Customer';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import DaliyPicker from '@/components/DatePicker/DaliyPicker_ocms';
import ExportBtn from '@/components/ExportBtn';
import statusCode from '@/assets/constant/statusCode';
import SingleSelectCurrency from '@/components/SingleSelectCurrency';

export default {
	components: {
		'v-chart': ECharts,
		ValidationObserver,
		ValidationProvider,
		DaliyPicker,
		ExportBtn,
		SingleSelectCurrency
	},
	data() {
		return {
			dialog: false,
			windowSize: {
				x: 0,
			},
			getExportResult: [],
			helperShow: false,
			currencyStatus: true,
			show: false,
			tab: null,
			drop: false,
			menuProps: {
				offsetY: true,
			},
			rowsPerPageItems: [100, 200, 500, 1000, -1],
			areaSwitch: true,
			domainSwitch: true,
			loading: false,
			dates: [],
			dateType: 'MM/DD',
			searchDates: [],
			area: [],
			areaList: [],
			value: [],
			domain: [],
			userColumn: 'dim_member_user_id',
			originalChipList: [],
			chipCardLoading: false,
			chipMap: {},
			xlsxMap: {},
			chipData: [],
			defaultChip: [],
			selectedMember: '',
			basic: {
				headers: [],
				items: [],
			},
			enterMemberAccount: 'Enter Member Account',
			imgSrc: require('@/assets/image/research.png'),
			fixedTitleList: {
				dim_member_user_id: { name: this.$t('customer.memberUserID'), value: true },
			},
			oldTagList: {},
			tagList: {
				'this_attn_term': { name: this.$t('customer.attnTerm'), value: true },
				'ttl_reg_attn_term': { name: this.$t('customer.ttlRegAttnTerm'), value: true },
				'ttl_login_attn_term': { name: this.$t('customer.ttlLoginAttnTerm'), value: false },
				'ttl_login_count': { name: this.$t('customer.ttlLoginCount'), value: false },
				'ttl_gm_attn_term': { name: this.$t('customer.ttlGmAttnTerm'), value: true },
				'ttl_gm_count': { name: this.$t('customer.ttlGmCount'), value: false },
				'ttl_gm_apv_bet_amt': { name: this.$t('customer.ttlGmApvBetAmt'), value: true },
				'ttl_gm_net_win_amt': { name: this.$t('customer.ttlGmNetWinAmt'), value: false },
				'ttl_gm_auto_bet_amt': { name: this.$t('customer.ttlGmAutoBetAmt'), value: true },
				'ttl_dpst_attn_term': { name: this.$t('customer.ttlDpstAttnTerm'), value: false },
				'ttl_dpst_count': { name: this.$t('customer.ttlDpstCount'), value: false },
				'ttl_dpst_amt': { name: this.$t('customer.ttlDpstAmt'), value: false },
				'ttl_revenue_amt': { name: this.$t('customer.ttlDpstRevenueAmt'), value: false },
				'ttl_wdrl_attn_term': { name: this.$t('customer.ttlWdrlAttnTerm'), value: false },
				'ttl_wdrl_count': { name: this.$t('customer.ttlWdrlCount'), value: false },
				'ttl_wdrl_amt': { name: this.$t('customer.ttlWdrlAmt'), value: false },
				'ttl_promo_join_count': { name: this.$t('customer.ttlPromoBindedJoinCount'), value: false },
				'ttl_promo_done_count': { name: this.$t('customer.ttlPromoBindedClearCount'), value: false },
				// 'ttl_promo.bonus_in_amt': { name: '', value: false },
				// 'ttl_promo.bonus_out_amt': { name: '', value: false },
				'ttl_promo_bonus_amt': { name: this.$t('customer.ttlPromoBindedBonusAmt'), value: false },
				'ttl_promo_apv_bet_amt': { name: this.$t('customer.ttlPromoBindedApvBetAmt'), value: false },
				'ttl_promo_net_win_amt': { name: this.$t('customer.ttlPromoBindedNetWinAmt'), value: false },
				'ttl_promo_dpst_count': { name: this.$t('customer.ttlPromoBindedDpstCount'), value: false },
				'ttl_promo_dpst_amt': { name: this.$t('customer.ttlPromoBindedDpstAmt'), value: false },
				// 'ttl_promo_normal.bonus_amt': { name: this.$t('customer.ttlPromoNormalBonusAmt'), value: false },
				'avg_gm_count': { name: this.$t('customer.avgGmCount'), value: false },
				'avg_gm_apv_bet_amt': { name: this.$t('customer.avgGmApvBetAmt'), value: true },
				'avg_gm_auto_bet_amt': { name: this.$t('customer.avgGmAutoBetAmt'), value: true },
				'avg_dpst_amt': { name: this.$t('customer.avgDpstAmt'), value: true },
				'avg_dpst_count': { name: this.$t('customer.avgDpstCount'), value: false },
				'ttl_reg_attn_time_min': { name: this.$t('customer.ttlRegAttnTimeMin'), value: true },
				'ttl_login_attn_time_max': { name: this.$t('customer.ttlLoginAttnTimeMax'), value: false },
				'ttl_gm_attn_time_max': { name: this.$t('customer.ttlGmAttnTimeMax'), value: true },
				'ttl_dpst_attn_time_max': { name: this.$t('customer.ttlDpstAttnTimeMax'), value: false },
				'ttl_wdrl_attn_time_max': { name: this.$t('customer.ttlWdrlAttnTimeMax'), value: false },
				'avg_gm_apv_bet_amt_pr': { name: this.$t('customer.avgGmPrApvBetAmt'), value: true },
				'avg_gm_auto_bet_amt_pr': { name: this.$t('customer.avgGmPrAutoBetAmt'), value: true },
				'avg_dpst_amt_pr': { name: this.$t('customer.avgDpstPrAmt'), value: true },
				'avg_gm_count_pr': { name: this.$t('customer.avgGmPrCount'), value: false },
				'avg_dpst_count_pr': { name: this.$t('customer.avgDpstPrCount'), value: false },
				'avg_gm_stickiness': { name: this.$t('customer.avgGmSticky'), value: true },
				'avg_dpst_stickiness': { name: this.$t('customer.avgDpstSticky'), value: true },
			},
		};
	},
	mounted() {
		this.initData();
		this.matchedIndexPin();
		this.onResize();
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog', 'setOperatorDefaultRouterTab', 'setOpenAutoSearch"']),
		initData() {
			this.$emit('emitsavePath', this.$route.path);
		},
		onResize() {
			this.windowSize = { x: window.innerWidth };
		},
		async send() {
			this.allClean();
			this.show = !this.show;
			this.loading = !this.loading;
			try {
				const reqData = {
					startDate: this.$moment(this.dates[0]).format('YYYY-MM-DD'),
					endDate: this.$moment(this.dates[1]).format('YYYY-MM-DD'),
					domain: [this.value],
					currency: this.currency,
					columns: this.getColumn(this.chipData)
				};
				this.searchDates = [reqData.startDate, reqData.endDate];
				const res = await getMemberLookup(reqData);
				if (res.status === statusCode.STATUS_OK) {
					const result = JSON.parse(JSON.stringify(res.result.res));
					localStorage.setItem('currency', this.currency);
					this.getExportResult = JSON.parse(JSON.stringify(result));
					this.oldTagList = JSON.parse(JSON.stringify(this.tagList));
					this.createChip(result[0]);
				}
			} catch (e) {
				errorLogger(e);
				this.allClean();
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		createChip(data) {
			this.chipData = [];
			let key = Object.keys(data);
			key = key.filter(function (item) {
				return item != 'dim_member_user_id';
			});
			this.originalChipList = JSON.parse(JSON.stringify(key));
			for (let i of this.originalChipList) {
				if (this.tagList[i]) {
					if (this.tagList[i].value) {
						this.chipData.push(this.tagList[i].name);
					}
					this.chipMap[this.tagList[i].name] = i;
				}
			}
			if(this.getExportResult.length < 1) {
				this.defaultChip = JSON.parse(JSON.stringify(this.chipData));
			} else {
				this.oldTagList =  JSON.parse(JSON.stringify(this.tagList));
				this.dataTable(this.getExportResult);
			}
		},
		initChip() {
			this.chipData = JSON.parse(JSON.stringify(this.defaultChip));
		},
		dataTable(data) {
			const head = [];
			const tableHeader = JSON.parse(JSON.stringify(this.tagList));
			const fixedTitle = JSON.parse(JSON.stringify(this.fixedTitleList));
			Object.assign(fixedTitle, tableHeader);
			const title = Object.keys(fixedTitle); // all title list
			for (let i of title) {
				this.xlsxMap[i] = fixedTitle[i].name;
				if (fixedTitle[i].value) {
					head.push({
						text: fixedTitle[i].name,
						value: i,
						width: '210px',
						align: 'center',
					});
				}
			}
			this.basic.headers = head;
			this.basic.items = JSON.parse(JSON.stringify(data));
		},
		searchMember(member) {
			this.dialog = true;
			this.selectedMember = member.dim_member_user_id;
		},
		jumpPage(memberID) {
			localStorage.setItem(
				'searchMember',
				JSON.stringify({
					area: this.area,
					domain: this.value,
					member: memberID,
				})
			);
			this.closeDialog();
			// this.$router.push({ path: '/customer/single' });
		},
		allSelect() {
			this.chipCardLoading = true;
			this.chipData = Object.keys(this.chipMap);
			setTimeout(() => (this.chipCardLoading = false), 2000);
		},
		cancelSelect() {
			this.chipCardLoading = true;
			this.chipData = [];
			setTimeout(() => (this.chipCardLoading = false), 2000);
		},
		allClean() {
			// this.originalChipList = [];
			// this.chipData = [];
			// this.selectedMember = '';
			this.basic = {
				headers: [],
				items: [],
			};
		},
		getColumn(data) {
			const columns = [this.userColumn];
			for(let i of data) {
				if (this.chipMap[i] === 'ttl_promo_bonus_amt') {
					columns.push('ttl_promo_bonus_in_amt', 'ttl_promo_bonus_out_amt');
				} else {
					columns.push(this.chipMap[i]);
				}
			}
			return columns;
		},
		updateDomainValue(val, val2, val3) {
			this.value = val;
			this.area = val2;
			this.currency = val3;
		},
		async exportXlsxFile() {
			try {
				const data = [],
					key = [this.userColumn];
				const result = JSON.parse(JSON.stringify(this.getExportResult));
				for (let i of this.chipData) {
					key.push(this.chipMap[i]);
				}
				for (let i of result) {
					let tmp = {};
					for (let k of key) {
						tmp[this.xlsxMap[k]] = i[k];
					}
					data.push(tmp);
				}
				let obj = {
					[this.value]: data,
				};
				await exportXlsx('d', this.searchDates, obj);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
		},
		closeDialog() {
			this.dialog = false;
		},
		showhelperText() {
			if (Object.keys(this.getExportResult).length === 0) {
				this.helperShow = true;
			}
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg,
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		},
		selectItemName(data, items, index) {
			return index === 0 && data;
		},
		matchedIndexPin() {
			let operatorDefaultRouter = this.getOperatorDefaultRouter.split(',');
			if (this.$route.name === operatorDefaultRouter[0] && this.getOpenAutoSearch) {
				if (operatorDefaultRouter[1] == 'daily') {
					this.tab = 0;
				} else if (operatorDefaultRouter[1] == 'monthly') {
					this.tab = 1;
				}
			}
		},
		goSearch() {
			$('body,html').animate({ scrollTop: 0 }, 800);
		},
		updateDate(val) {
			this.dates = val;
		},
	},
	computed: {
		...mapGetters([
			'getDomain',
			'getExchangeRate',
			'getDialogShow',
			'getStatusDialog',
			'getOperatorDefaultRouter',
			'getOpenAutoSearch',
		]),
	},
	watch: {
		tab: function () {
			$(function () {
				$('.fixBar').removeClass('dateFixBar');
				$(window).scroll(function () {
					let scrollVal = $(this).scrollTop();
					let div = $('.div-w');
					if (div.length) {
						let adscrtop = div.offset().top;
						if (scrollVal + 44 > adscrtop) {
							$('.fixBar').addClass('dateFixBar');
						} else {
							$('.fixBar').removeClass('dateFixBar');
						}
					}
				});
			});
		},
		// domain: function (value) {
		// 	const arr = JSON.parse(JSON.stringify(value));
		// 	if (arr.length === 0) {
		// 		this.turnOff = true;
		// 	} else {
		// 		this.turnOff = false;
		// 	}
		// },
		// area: function (value) {
		// 	let domainValue = this.getDomain['OCMS'][value];
		// 	let tmp = Object.values(domainValue);
		// 	this.domain = tmp;
		// 	if (this.domain.length <= 1) {
		// 		this.domainSwitch = false;
		// 		this.value = this.domain[0];
		// 	} else {
		// 		this.domainSwitch = true;
		// 	}
		// },
		chipData: function (value) {
			const arr = [];
			const tmp = JSON.parse(JSON.stringify(this.tagList));
			for (let i of value) {
				arr.push(this.chipMap[i]);
			}
			let set = new Set(arr);
			let result = this.originalChipList.filter((e) => {
				return !set.has(e);
			});
			for (let i of arr) {
				tmp[i].value = true;
			}
			for (let i of result) {
				if (tmp[i]) {
					tmp[i].value = false;
				}
			}
			this.tagList = tmp;
		},
		getExportResult: function () {
			if (Object.keys(this.getExportResult).length !== 0) {
				this.helperShow = false;
			}
		},
	},
	created() {
		// this.areaList = Object.keys(this.getDomain['OCMS']);
		// this.areaList.sort();
		// if (this.areaList.length <= 1) {
		// 	this.areaSwitch = false;
		// 	this.area = this.areaList[0];
		// }
		this.createChip(this.tagList);
		this.allClean();
	},
};
</script>
