const transType = {
	1: 'Enter game',
	2: 'Transfer Fund into Game',
	3: 'Manual Deposit',
	4: 'Online Withdraw',
	5: 'Member Deposit (Cash Counter)',
	6: 'Member Withdraw (Cash Counter)',
	7: 'Promotion Bonus',
	8: 'Rolling Commission',
	10: 'Online Deposit',
	11: 'Online Redo Transaction',
	12: 'Member Deposit (Member of PT Agent)',
	13: 'Member Withdraw (Member of PT Agent)',
	14: 'Member Deposit (Cash Website)',
	15: 'Member Withdraw (Cash Website)',
	16: 'System Transfer',
	19: 'Promtion Recycling',
	20: 'System Recycling',
	23: 'Promotion Wallet',
	24: 'Other deposits',
	25: 'Other refunds',
	26: 'Auto Cash-In',
	27: 'Bill-In',
	40: 'PromotionTicket To PromotionWallet'
};
export default transType;
